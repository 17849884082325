body {
  margin: 0;
  font-family: "Lato", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #E6F7E9;
  cursor: url('./assets/cursor/turtle-dark.png') 28 0, auto;
  transition: background-color 0.5s, background-image 5s;
  backdrop-filter: none;
}

.image-background {
  background-image: url('./assets/background/background.png');
  background-color: white;
  transition: background-color 0.5s, background-image 5s;
}

.image-background-green {
  background-image: url('./assets/background/background.png');
  background-color: rgba(241, 255, 217, 0.347);
  transition: background-color 0.5s, background-image 5s;
}

.green-background {
  background-color: #E6F7E9;
  background-image: none;
  transition: background-color 0.5s, background-image 5s;
}
